import api from '../../helpers/apirest/api';

export const state = {
    customerTaskFieldTypes: null
}

export const mutations = {
    SET_CUSTOMER_TAKS_FIELD_TYPES(state, newValue) {
        state.customerTaskFieldTypes = newValue;
    },
}

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.customerTaskFieldTypes){
            const response = await api.get({
                url: 'customer-task-field-types'
            });
    
            const data = response?.data?.data;         
    
            commit('SET_CUSTOMER_TAKS_FIELD_TYPES', data);
        }        
    }
}