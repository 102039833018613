import api from '../../helpers/apirest/api';

export const state = {
    sourceDriverTypes: null
}

export const mutations = {
    SET_SOURCE_DRIVER_TYPES(state, newValue) {
        state.sourceDriverTypes = newValue;
    },
}

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.sourceDriverTypes){
            const response = await api.get({
                url: 'source-driver-types'
            });
    
            const data = response?.data?.data;         
    
            commit('SET_SOURCE_DRIVER_TYPES', data);
        }        
    }
}