import api from '../../helpers/apirest/api';

export const state = {
    sourceDrivers: null
}

export const mutations = {
    SET_SOURCE_DRIVERS(state, newValue) {
        state.sourceDrivers = newValue;
    },
}

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.sourceDrivers){
            const response = await api.get({
                url: 'source-drivers'
            });
    
            const data = response?.data?.data;         
    
            commit('SET_SOURCE_DRIVERS', data);
        }        
    }
}