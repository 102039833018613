import api from '../../helpers/apirest/api';

export const state = {
    targetDrivers: null
}

export const mutations = {
    SET_TARGET_DRIVERS(state, newValue) {
        state.targetDrivers = newValue;
    },
}

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.targetDrivers){
            const response = await api.get({
                url: 'target-drivers'
            });
    
            const data = response?.data?.data;         
    
            commit('SET_TARGET_DRIVERS', data);
        }        
    }
}