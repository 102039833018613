import api from '../../helpers/apirest/api';

export const state = {
    targetDriverTypes: null
}

export const mutations = {
    SET_TARGET_DRIVER_TYPES(state, newValue) {
        state.targetDriverTypes = newValue;
    },
}

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.targetDriverTypes){
            const response = await api.get({
                url: 'target-driver-types'
            });
    
            const data = response?.data?.data;         
    
            commit('SET_TARGET_DRIVER_TYPES', data);
        }        
    }
}