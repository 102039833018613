import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password',
        name: 'Reset-password',
        component: () => import('../views/pages/account/reset-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            async beforeResolve(routeTo, routeFrom, next) {
                await store.dispatch('auth/logout');
                /*const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )*/
                // Navigate back to previous page, or home as a fallback
                //next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
                next( { name: 'login' } )
            },
        },
    },
    {
        path: '/stores',
        name: 'stores',
        meta: {
            authRequired: true,
            permission:"menuitem.show"
        },
        component: () => import('../views/pages/stores/stores')
    },
    {
        path: '/orders',
        name: 'orders',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/orders/orders')
    },
    {
        path: '/customers',
        name: 'customers',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/customers/customers')
    },
    {
        path: '/card-applications',
        name: 'card-applications',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/card-applications/card-applications')
    },
    {
        path: '/products',
        name: 'products',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/products/products'),
    },
    {
        path: '/product-categories',
        name: 'productCategories',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/product-categories/product-categories'),
    },
    {
        path: '/coupons',
        name: 'coupons',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/coupons/coupons'),
    },
    {
        path: '/integrations',
        name: 'integrations',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/integrations/integrations')
    },
    {
        path: '/app-tokens',
        name: 'app-tokens',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/app-tokens/app-tokens')
    },
    {
        path: '/users',
        name: 'users',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/users/users')
    },
    {
        path: '/tax-rates',
        name: 'taxRates',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/tax-rates/tax-rates'),
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
]
